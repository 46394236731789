import React from 'react'
import Layout from '../components/Layout'
import {StaticImage} from 'gatsby-plugin-image'
import {Link} from 'gatsby'
import Seo from '../components/Seo'

const Portfolio = () => {
    return (
 <Layout>
     <Seo  title="Portfolio"/>
            <main className="page">
            <section className="about-page">
                <article>
                    <h5>Portfolio</h5>
                    <p>
                        Onderstaand enkele voorbeelden uit ons portfolio.
                    </p>
                    <p>
                      Loyaliteit app: gebouwd met React en AWS back-end voor authorisatie 
                      en content distributie. 
                    </p>
                    <p>
                      Platform site: Platform met AWS back-end voor compute en transactieverwerking. 
                    </p>
                    <Link to="/contact" className="btn">
                        contact
                    </Link>
                </article>
                <StaticImage 
                src="../assets/images/portfoliophoto.jpg" 
                alt="Astronaut pointing to the cloud" 
                className = "about-img"
                placeholder ="blurred"/>
            </section>
            </main>
        </Layout>
    )
}

export default Portfolio